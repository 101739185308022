import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import flags from 'containers/flags/service';

import { isInternalUser } from 'states/profile/business-rules';
import { getEnv } from 'services/environment';
import selector from './selector';
import AdForm from 'forms/ad-form';
import AdNew from 'pages/ad-form/ad-new-form/actions';

class Ad_new extends React.Component {
    handleButtonClick_submit = draft => {
        const { dispatch, campaignId } = this.props;

        return dispatch(AdNew.submitDraft(draft, campaignId)).then(() => {
            browserHistory.push(`/campaigns/${campaignId}/setup/ads`);
        });
    };

    handleButtonClick_cancel = () => {
        const { campaignId, isSubmiting } = this.props;

        if (isSubmiting) {
            reutrn;
        }

        browserHistory.push(`/campaigns/${campaignId}/setup/ads/`);
    };

    getInitialDraft = () => {
        const {
            dispatch,
            params: { campaignId, adId },
        } = this.props;

        return dispatch(AdNew.open(campaignId, adId));
    };

    render() {
        const { campaignId } = this.props.params;

        if (this.props.loading) {
            return <div>loading...</div>;
        }

        return (
            <div>
                <AdForm
                    useTactics={!!this.props.location.query.tactics}
                    submit={this.handleButtonClick_submit}
                    cancel={this.handleButtonClick_cancel}
                    campaignId={campaignId}
                    getInitialDraft={this.getInitialDraft}
                />
                {this.props.workspace}
            </div>
        );
    }
}

export default connect(selector)(Ad_new);
